//// mixins

@mixin fontSize($size) {
    font-size: relativePixelSize($size);
}

@function relativePixelSize($size) {
    @return calc(100vw * #{$size}/ var(--screenXRatio));
}

/////////////////////////

*,
*:after,
*:before {
    box-sizing: border-box;
}

:root {
    --screenXRatio: 1920;
    --gapwidth: calc(100vw * 40 / var(--screenXRatio));
}

@media (orientation: portrait) {
    :root {
        --screenXRatio: 1080;
    }
}

#root {
    overflow: hidden;
    height: 100vh;
    position: relative;
    cursor: none;
    font-family: "Work Sans";
}

body {
    margin: 0;
    font-family: "Work Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
    height: 100vh;
    position: relative;
}

.custom-message {
    font-weight: 700;
    @include fontSize(25);
    color: #065498;
    padding: 1em 1.3em;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-color: #dfedf1;
}

.snackbar {
    padding: 1em;
    color: white;
    background-color: black;
    border-radius: 0.4em;
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    @include fontSize(20);
}

///// loading skeleton

[data-loading] {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ddd;
        transition: all 0.3s;
        z-index: 1;
        opacity: 0;
        animation: loadingAnimation 0.8s infinite alternate;
    }
}

[data-loading="true"] {
    &:after {
        opacity: 1;
    }
}

// .property-image[data-loading="true"] {
//     aspect-ratio: 1.5;
// }

@keyframes loadingAnimation {
    from {
        background: #ddd;
    }

    to {
        background: #eee;
    }
}

///// border things on the fifty fifty

.border-template {
    padding: var(--gapwidth);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.border {
    // This will look smaller on 4k screens
    width: 2000px;
    height: 2000px;
    position: fixed;
    z-index: -1;
}

// the naming for dark, light, top, bottom, left, right are used to generate the classes in the code.
.border--dark {
    background-color: #005398;
}

.border--light {
    background-color: #08bae3;
}

.border--topleft {
    transform-origin: bottom right;
    transform: translate(-100%, -100%) rotate(-45deg);
}

.border--topright {
    transform-origin: bottom left;
    transform: translate(100%, -100%) rotate(45deg);
}

.border--bottomleft {
    transform-origin: top right;
    transform: translate(-100%, 100%) rotate(45deg);
}

.border--bottomright {
    transform-origin: top left;
    transform: translate(100%, 100%) rotate(-45deg);
}

////// header

.header {
    background-color: black;
    padding: relativePixelSize(20);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    svg {
        display: block;
        height: relativePixelSize(40);
    }
}

///// template selector

.template-selector {
    position: relative;
    height: 100%;
    width: 100%;
}

.template-selector__animated {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

///// transition image

.transition-image {
    position: relative;
    height: 100%;
    width: 100%;
}

///// image template

.image-template {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    img[src*="photo-placeholder"] {
        background: #ddd;
    }
}

////// property template

.property-container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    grid-gap: var(--gapwidth);
    gap: var(--gapwidth);
    padding: var(--gapwidth);
    flex-grow: 1;
}

.property-images {
    display: flex;
    grid-gap: var(--gapwidth);
    gap: var(--gapwidth);
    flex-grow: 1;
    width: 100%;
    align-items: stretch;
}

.property-image--main {
    flex: 2;
}

.property-images-small {
    display: flex;
    flex-direction: column;
    grid-gap: var(--gapwidth);
    gap: var(--gapwidth);
    flex: 1;
    .property-image {
        flex: 1;
    }
}

.transition-image__animated {
    width: 100%;
    height: 100%;
    position: absolute;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        background-color: #e5e5e5;
    }
}

.transition-image__animated--placeholder img {
    object-fit: contain;
}

.property-info {
    width: 100%;
    //min-height: relativePixelSize(388); // based on min content contained with the
    color: #4c4c4c;
    .aspc-ref {
        margin-top: auto;
    }
    .separator {
        padding: 0 relativePixelSize(40);
        color: #cacaca;
    }

    .rooms {
        font-weight: 700;
        padding-right: relativePixelSize(40);
        display: flex;
        align-items: center;
        svg {
            margin-right: relativePixelSize(10);
            @include fontSize(30);
        }
    }
    .bedroom {
        color: #20b5e1;
        fill: #bde9f6;
        svg {
            width: 1.15em;
            height: 1.15em;
        }
    }
    .publicrooms {
        color: #fd822e;
        fill: #ffecdf;
        svg {
            width: 1.6em;
            height: 1.6em;
        }
    }
    .bathrooms {
        color: #fe3f8c;
        fill: #fff1f6;
        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }

    #epc,
    #counciltax {
        padding-left: relativePixelSize(10);
    }

    .property-type {
        color: #005398;
        fill: #b2dcff;
        @include fontSize(30);
    }

    .property-type-semi-detached {
        width: 1.7em;
        height: 1em;
    }

    .property-type-terraced {
        width: 2.5em;
        height: 1em;
    }

    .property-type-flat {
        width: 1.2em;
        height: 1.2em;
    }

    .property-type-detached {
        width: 1em;
        height: 1em;
    }

    .features-bar {
        font-weight: 400;
        @include fontSize(32);
        display: flex;
        align-items: center;
        padding-top: relativePixelSize(30);
    }

    .price-bar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media (orientation: landscape) {
    .property-info {
        display: flex;
        justify-content: space-between;
    }

    .property-images-small:last-child {
        display: none;
    }

    .property-info .price-bar {
        justify-content: space-between;
    }
}

@media (orientation: portrait) {
    .property-images {
        flex-direction: column;
    }

    .property-images-small {
        flex-direction: row;
        // flex: initial;
    }

    .property-info .price-bar {
        margin-top: var(--gapwidth);
        align-items: flex-start;
    }
}

///// general text sizing

.template-heading-text,
.template-sub-text,
.template-small-text,
.property-heading-text,
.property-sub-text {
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.template-heading-text {
    @include fontSize(62);
    font-weight: 700;
}

.template-sub-text {
    font-weight: 400;
    @include fontSize(34);
}

.property-heading-text {
    @include fontSize(49);
    font-weight: 700;
}

.property-sub-address {
    @include fontSize(32);
}

.property-sub-text {
    font-weight: 300;
    @include fontSize(24);
}

.template-small-text {
    @include fontSize(20);
}

// some tailwind-like overrides (not really)
.light-font {
    font-weight: 300;
}

.bold-font {
    font-weight: 700;
}

///// text content container for inside 50-50 and text templates

.text-content {
    width: 100%;
    max-width: relativePixelSize(1300); // 1100 + 100 + 100
    padding: 0 relativePixelSize(100);

    .template-heading-text {
        margin-bottom: relativePixelSize(40);
    }

    p {
        padding: 0;
        margin: 0;
    }

    p:empty:after {
        content: "\00a0";
    }
}

.text-template .text-content {
    text-align: center;
}

///// fifty fifty

.fifty-fifty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fifty-fifty__panel {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media (orientation: landscape) {
    .fifty-fifty[data-reverse="false"] {
        flex-direction: row-reverse;
    }
}

@media (orientation: portrait) {
    .fifty-fifty {
        flex-direction: column;
    }

    .fifty-fifty__panel {
        width: 100%;
        height: 50%;
    }

    .fifty-fifty[data-reverse="false"] {
        flex-direction: column-reverse;
    }
}

///// text template

.text-template {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

///// video template

.video {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        transition: opacity 0.3s;
        opacity: 0;
    }

    &[data-state="ending"]:before {
        opacity: 1;
    }
}
